const words = [
  'ถ้าเธอยังว่าง',
  'โลกนี้มันไม่ง่าย',
  'เบื่อจังชีวิตโสด',
  'มีความสุขกับคนที่รักคุณ',
  'เป็นแค่เวลาสั้นๆ',
  'แบตหมดให้เดินหาปลั๊ก',
  'อย่าทำร้ายเพื่อน',
  'นอนน้อยตาเบลอ',
  'อย่าทำร้ายคนอื่น',
  'แค่เธอหันมา',
  'ดูหนังห้องเรามั้ย',
  'เป็นเพื่อนที่รักคุณ',
  'ไม่รู้ไม่รู้ไม่รู้',
  'ถึงพี่ไม่โสด',
  'อย่าโกรธเราเลย',
  'จุดจบของคนจริง',
  'ลืมเขาไม่ได้ต้องทำยังไง',
  'สร้างภาระมากเกินไป',
  'รับจ้างเป็นแฟน',
  'นิ้วนางยังว่าง',
  'หน้าตาดีมั้ยไม่รู้',
  'ช่วงนี้อ่อนแอ',
]

export default words
